import React from "react";
import { Fade } from "react-reveal";
import "./expertise.scss";

export default function Expertise() {
  return (
    <div className="expertise container" id="expertise">
      <div className="introduce">
        <Fade bottom duration={2000}>
          <h2>Hi, I’m G'ayratjon</h2>
        </Fade>
        <Fade bottom delay={500} duration={1500}>
          <p>
            I'm originally from Namangan, Uzbekistan, and now I live in the
            vibrant city of Tashkent. I'm currently pursuing my studies distantly
            at the Fergana branch of the Tashkent University of Information
            Technologies, majoring in Computer Engineering. As a passionate
            Front End Web Developer, I transform creative ideas into visually
            appealing and functional web experiences. My journey in web
            development has equipped me with the skills and enthusiasm to craft
            dynamic, user-friendly websites. Explore my portfolio to see my work
            and learn more about my journey in the world of web development.
          </p>
        </Fade>
      </div>
      <div className="skills">
        <Fade bottom duration={2000} delay={500}>
          <div className="skills-box">
            <div className="card card-1">
              <div className="blog">
                <div className="blog-top">
                  <span>
                    <i className="bx bx-desktop"></i>
                  </span>
                  <h2>Software Engineer</h2>
                  <p>
                    I value simple content structures, clean design patterns,
                    and thoughtful interactions. I’m passionate about creating
                    intuitive and efficient user experiences.
                  </p>
                </div>
                <div className="blog-center">
                  <p className="blog-center-title">Things I enjoy designing:</p>
                  <p className="blog-center-text">
                    Html, Css, Sass, Bootstrap, Ant Design, ReactJs
                  </p>
                </div>
              </div>
            </div>
            <div className="card card-3">
              <div className="blog">
                <div className="blog-top">
                  <span>
                    <i className="bx bx-code-alt"></i>
                  </span>
                  <h2>Frontend Developer</h2>
                  <p>
                    I thrive on coding projects from scratch and love bringing
                    creative ideas to life in the browser.
                  </p>
                </div>
                <div className="blog-center">
                  <p className="blog-center-title">Languages I speak:</p>
                  <p className="blog-center-text">JavaScript, TypeScript</p>
                </div>
              </div>
            </div>
            {/* <div className="card card-3">
              <div className="blog">
                <div className="blog-top">
                  <span>
                    <i className="bx bxl-react"></i>
                  </span>
                  <h2>React Native Developer</h2>
                  <p>
                    I genuinely care about people, and love helping fellow
                    designers work on their craft.
                  </p>
                </div>
                <div className="blog-center">
                  <p className="blog-center-title">Experiences I draw from:</p>
                  <p className="blog-center-text">
                    UX/UI, Product design, Freelancing
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </Fade>
      </div>
    </div>
  );
}
