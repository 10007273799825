export const projectsData = [
  {
    id: 1,
    title: "ShareMe",
    desc: "I developed the ShareMe application, a dynamic social media platform where users can upload pictures, write comments, and sign in using their Google accounts. The frontend is built with React, while the backend is powered by Sanity.",
    tools: ["React", "Css", "JavaScript", "Tailwind", "Sanity"],
    url: "https://www.shareme.uz/",
    github: "https://github.com/Gayratjon2003/shareme_frontend",
    img: "project5.png",
  },
  {
    id: 2,
    title: "Cryptoverse",
    desc: "I developed Cryptoverse, a React-based website. The project leverages various technologies such as Chart.js, RTK Query, and Ant Design, which is an excellent library for creating elegant user interfaces. On Cryptoverse, you can explore a wealth of information on cryptocurrencies, news, exchange stats, and more. Visit the site and enjoy the experience!",
    tools: ["React", "Redux", "Css", "JavaScript", "Chartjs", "Ant Design"],
    url: "https://gayratjon2003.github.io/cryptoverse/",
    github: "https://github.com/Gayratjon2003/cryptoverse",
    img: "project1.png",
  },
  {
    id: 3,
    title: "Collections Manager",
    desc: "I developed this website using the MERN stack, implementing features for user, non-user, and admin roles. The site supports both dark and light modes, with localStorage handling language and theme preferences. Leveraging Material UI, the website includes functionalities such as login, registration, search, and more.",
    tools: [
      "React",
      "Redux",
      "Css",
      "JavaScript",
      "MUI",
      "Nodejs",
      "Expressjs",
      "MongoDB",
    ],
    url: "https://collections-manager.netlify.app/",
    github: "https://github.com/Gayratjon2003/final-project-frontend",
    img: "project2.jpg",
  },
  {
    id: 4,
    title: "Rock paper Scissors",
    desc: "I developed a game website with a robust architecture. The frontend is built using HTML, CSS, and React, ensuring a dynamic and responsive user experience. For the backend, I utilized Node.js and socket.io to enable real-time interactions and seamless gameplay. You can also play the game with your friends, making it a fun and engaging experience.",
    tools: ["Html", "Css", "React", "Nodejs", "Socket.io", "Nodejs"],
    url: "https://scissors-gayratjon.netlify.app/",
    github: "https://github.com/Gayratjon2003/scissors-frontend",
    img: "project3.jpg",
  },
  {
    id: 5,
    title: "Movie App",
    desc: "I developed a movie-app website using modern web technologies such as JSX, Sass, React, and Redux. The application integrates with the OMDb API (https://www.omdbapi.com/) to fetch movie data. I deployed the project on GitHub Pages for easy access and sharing.",
    tools: ["Html", "Sass", "Jsx", "React", "Redux", "omdbApi"],
    url: "https://gayratjon2003.github.io/movie-app/",
    github: "https://github.com/Gayratjon2003/movie-app",
    img: "project4.png",
  },
  {
    id: 6,
    title: "IlmHub",
    desc: "We developed this website for an IT education center, utilizing HTML, SCSS, JavaScript, Swiper.js, and AOS. The site features engaging animations and swiper functionality to enhance the user experience. It showcases the dynamic aspects of web design and provides an interactive platform for learning about IT education.",
    tools: ["Html", "Sass", "Jsx", "React", "Redux", "omdbApi"],
    url: "https://gayratjon2003.github.io/IlmHub/index1.html",
    github: "https://github.com/Gayratjon2003/IlmHub",
    img: "project6.jpg",
  },
];
