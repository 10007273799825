import React from "react";
import { Fade } from "react-reveal";
import "./main.scss";

export default function Main() {
  return (
    <>
      <div className="contact">
        <div className="contact_content">
          <Fade top duration={1000}>
            <h3>Contacts</h3>
          </Fade>
          <Fade bottom duration={1000}>
            <ul>
              <li>
                <i className="bx bx-envelope"></i>
                <a href="mailto:gayratjonabdijobborov@gmail.com">
                  gayratjonabdijobborov@gmail.com
                </a>
              </li>
              <li>
                <i className="bx bx-phone"></i>
                <a href="tel:+998942776026">Phone: +998 (94) 277 60 26</a>
              </li>
              <li>
                <i className="bx bx-home"></i>
                Address: Tashkent, Uzbekistan
              </li>
              <Fade bottom duration={1000}>
                <li className="social_networks">
                  <a href="https://github.com/Gayratjon2003" target={"_blank"}>
                    <i className="bx bxl-github"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/gayratjon2003/"
                    target={"_blank"}
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/gayratjon2003"
                    target={"_blank"}
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@gayratjondev"
                    target={"_blank"}
                  >
                    <i className="bx bxl-youtube"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/gayratjon_2003/"
                    target={"_blank"}
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                </li>
              </Fade>
            </ul>
          </Fade>
        </div>
      </div>
    </>
  );
}
