export const experienceData = [
  {
    date: "April, 2023 - Present",
    company: "Koinot Software Interprise",
    url: "https://bazark.vercel.app/en",
    description: "",
    role: "Front End Web Developer",
    works: [
      "As a Front End Web Developer, I contributed to the development of an e-commerce platform named Bazark, creating user profiles, payment pages, footers, navbars, and more using Material UI, Redux Toolkit, and Axios.",
    ],
  },
  {
    date: "May, 2023 - July, 2023",
    company: "Itransition Group",
    url: "https://www.itransition.com",
    description: "",
    role: "Software development Internship",
    works: [
      "During my internship, I worked on various tasks using React, Redux Toolkit, Axios, Material UI, and Ant Design for the frontend. ",
      "For backend development, I utilized Node.js, Express.js, and MongoDB. ",
      "I hosted the frontend on Netlify and Vercel, and the backend on Cyclic and Render.",
      "My final project was the Collections Manager application, which can be accessed this link: https://collections-manager.netlify.app",
    ],
  },

  {
    date: "March, 2023 - April, 2023",
    company: "Jobo.uz",
    url: "https://www.jobo.uz",
    description: "",
    role: "Front End Web Developer",
    works: [
      "At Jobo.uz, I developed search filters for the jobo.uz site and created a website for testing new employees. ",
      "Additionally, I built endomarine-kids.uz, which includes a feature for users to submit their name and phone number, with the data being sent to Telegram.",
      "I specialize in creating dynamic and engaging single-page websites."
    ],
  },
  {
    date: "January, 2023 - April, 2023",
    company: "ShareMe",
    url: "https://www.shareme.uz/",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I developed the ShareMe application, a social media platform where users can upload pictures, write comments, and sign in with their Google accounts. ",
      "The frontend was built with React, while the backend used Sanity. ",
      "You can explore the application this link: https://www.shareme.uz/",
    ],
  },
  {
    date: "December, 2022 - January, 2023",
    company: "Cryptoverse",
    url: "https://gayratjon2003.github.io/cryptoverse/",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I created a React-based website called Cryptoverse, utilizing technologies such as Chart.js and RTK Query.",
      "The site offers information on cryptocurrencies, news, exchanges, and more. ",
      "You can check it out this link: https://gayratjon2003.github.io/cryptoverse/",
    ],
  },
  {
    date: "November, 2022",
    company: "Gosto",
    url: "https://gosto.netlify.app/",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I developed an e-commerce website using HTML, CSS, JSX, React, Redux, and Redux-Thunk. You can view the project this link: https://gosto.netlify.app/",
    ],
  },
  {
    date: "January, 2022 - November, 2022",
    company: "TenX",
    url: "https://gayratjon2003.github.io/TenX/login",
    description: "",
    role: "Front End Web Developer",
    works: [
      "I worked on the TenX project, which involved creating a React website with HTML, CSS, Sass, JavaScript, React, Redux, and Strapi for the backend. ",
      "This site includes video lessons for learning JavaScript, available this link: https://gayratjon2003.github.io/TenX/login",
    ],
  },
];
