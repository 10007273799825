import React from 'react'
import Navbar from '../../container/Navbar/Navbar'
import Main from './Main/Main'

function Contact() {
  return (
    <>
      <Navbar />
      <Main />
    </>
  )
}

export default Contact